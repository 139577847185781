@import '../../../scss/theme-bootstrap';

.my-account-auto-ship-v1 .product {
  *zoom: 1;
  @include swap_direction(margin, 0 0 15px 0);
}

.my-account-auto-ship-v1 .product:before,
.my-account-auto-ship-v1 .product:after {
  content: ' ';
  display: table;
}

.my-account-auto-ship-v1 .product:after {
  clear: both;
}

.my-account-auto-ship-v1 .product .product-thumb {
  float: #{$ldirection};
  margin-#{$rdirection}: 5px;
  padding-top: 2px;
  display: block;
  max-height: 150px;
  height: auto;
  max-width: 100%;
  width: auto;
}

.my-account-auto-ship-v1 .product .meta {
  overflow: hidden;
  color: #808080;
  font-size: 13px;
}

.my-account-auto-ship-v1 .product .meta h4.product-title {
  font-size: 13px;
  line-height: 15px;
  font-family: $base-font-family-roman;
  margin-bottom: 0;
  color: black;
  text-transform: none;
}

.my-account-auto-ship-v1 .product .meta h4.product-title a {
  color: #1f1f1f;
}

.my-account-auto-ship-v1 .product .meta h4.product-title a:hover {
  color: $color-cl-green-2;
}

.my-account-auto-ship-v1 .product .meta span {
  display: block;
  margin-bottom: 2px;
}

.my-account-auto-ship-v1 .product .meta .replenish {
  color: black;
}

.my-account-auto-ship-v1 .product .meta strong {
  font-family: $base-bold-font-family;
}

.my-account-auto-ship-v1 .product-col3 {
  max-height: 150px;
  overflow: hidden;
  *zoom: 1;
  @include swap_direction(margin, 0 0 17px 0);
  padding: 0;
}

.my-account-auto-ship-v1 .product-col3:before,
.my-account-auto-ship-v1 .product-col3:after {
  content: ' ';
  display: table;
}

.my-account-auto-ship-v1 .product-col3:after {
  clear: both;
}

.my-account-auto-ship-v1 .product-col3 > li {
  display: block;
  float: #{$ldirection};
  min-height: 150px;
  width: 50%;
  @include swap_direction(padding, 0 5px 0 0);
}

.my-account-auto-ship-v1 .product-col3 > li:last-child {
  padding: 0;
}

.my-account-auto-ship-v1 .my-account-touts {
  overflow: hidden;
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout {
  float: #{$ldirection};
  margin: 35px 0;
  min-height: 120px;
  @include swap_direction(padding, 0 20px 0 0);
  width: 50%;
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout.tout-last {
  border-#{$ldirection}: 1px solid #dadada;
  @include swap_direction(padding, 0 0 0 20px);
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout .tout-image-v1.tout {
  -webkit-background-size: auto auto;
  -moz-background-size: auto auto;
  -o-background-size: auto auto;
  background-size: auto auto;
  border: none;
  @include swap_direction(padding, 0 20px 20px 20px);
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout .tout-image-v1.tout .tout-info {
  width: auto;
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout .tout-image-v1.tout .tout-info .heading {
  @include swap_direction(padding, 10px 0 0 0);
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout .tout-image-v1.tout .tout-info .button {
  @include swap_direction(margin, 15px 0 0 0);
}

.my-account-auto-ship-v1 .my-account-touts .my-account-tout .tout-image-v1.tout .tout-info .button-secondary {
  background: #fff;
}
